import React from 'react'
import { Navigation }from '../components/navigationWhiteBG'
import Footer from '../components/footer'
import BlogPost from '../components/blogPost'
import { useParams } from 'react-router-dom'
import { blogs } from '../utils/projectInfo'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function Blog() {
  let { articleName } = useParams();
  console.log('post name: ', articleName)

  let post = blogs.filter(blog => blog.projectUrl === articleName)
  console.log(post)
  // get an array of blog post objects with id || projectUrl ,
  // filter blogs where post.projectUrl === articleName,
  // pass that post.post to BlogPost
  // if no match display 404 message
    return (<>
    <Navigation disableTransparency={true} />
    {post.length === 1 ? <BlogPost post={post[0].post} />:<div style={{width: '100%', maxWidth: '78rem',padding: '1rem', margin: ' 5rem auto 0'}}>Sorry project not found.Please try again later</div> }
    <Footer />
    </>)
}

export default Blog