import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../utils/device'
import Button from '../components/Button'
import { CSSTransition } from 'react-transition-group';


const Herowrapper =styled.section`
  display: flex;
  width: 100%;
  height: 80vh;
  background-color: white;
  align-items: center;
`

const HeroContainer=styled.div`
  margin: 0 auto;
  max-width: 78rem;
  display: flex;
  width: 100%;
`
const HeroSpanContainer = styled.span`
  padding-left: 1rem;
  z-index: 5;
  @media ${device.tablet}{
    padding-left: 2.5rem;
  }
`
export default function Hero(){
  const [inProp, setInProp] = useState(false);
  useEffect(() => {
    setInProp(!inProp)
    
  }, [])
  return(
    <Herowrapper>
      <HeroContainer>
        <HeroSpanContainer>
        <CSSTransition timeout={500} in={inProp} classNames="alert">
         <div> <h1>We're a creative agency</h1> 
          <h2>for african brands and people</h2>
          <div style={{marginTop: '1rem', display: 'flex', flexWrap: 'wrap'}}>
  {/*<a href='#banner' style={{marginTop: '1.5rem',marginRight: '3rem', textDecoration: 'none',backgroundColor: 'black',color: 'white',padding: '1rem', boxSizing: 'border-box'}}>Learn More &darr; </a>*/}
          <Button to='/contact' type='primary'>Let's Work Together &rarr;</Button>
          </div>
          </div>
          </CSSTransition>
        </HeroSpanContainer>
      </HeroContainer>
    </Herowrapper>
  )
}
