import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/device'

function UnstyledImg ({className, content}){
    return(
        <img className={className} src={content.src} alt={content.alt} srcSet={content.srcSet} />
    )
}
const Image = styled(UnstyledImg)`
    max-width: 78rem;
    height: auto;
    width: 100%;
    margin: 0 auto;
    display: block;
    @media ${device.tablet}{
        width: 80%;
    }
`
const FullImage = styled(UnstyledImg)`
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: block;
    height: auto;
    box-sizing: border-box;
`
function TextBox({className, content }){
    return (
        <p className={className}>
           {content}
        </p>
    )
}
const Text = styled(TextBox)`
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    padding: 1rem;
    @media ${device.tablet}{
        max-width: 78%;
    }
    @media ${device.desktop}{
        max-width: 58%;
    }
    @media ${device.desktopL}{
        max-width: 78rem;
    }

`
const title = ({className, content}) =>{
    return (
        <h2 className={className}>{content}</h2>
    )
}
const Title = styled(title)`
margin: 0 1rem;
padding-bottom: 1rem;
@media max-width('500px'){

}
`
const unstyledSectionTitle = ({className, content}) =>{
    return (
        <h2 className={className}>{content}</h2>
    )
}
const SectionTitle= styled(unstyledSectionTitle)`
width: 100%;
max-width: 550px;
margin: 1.5rem auto 0;
padding: 0rem 1rem;
@media ${device.tablet}{
    max-width: 78%;
}
@media ${device.desktop}{
    max-width: 58%;
}
@media ${device.desktopL}{
    max-width: 78rem;
}
`
function unstyledComent({className, content}){
    return (<div className={className}>
        <h2>"{content.text}" </h2>
        <div className='profile-container'>
        <img src={content.img.src} alt={content.img.alt} />
        <em>{content.name}</em>
        </div>
    </div>)
}
const Comment = styled(unstyledComent)`
width: 100%;
max-width: 450px;
margin: 0 auto;
padding: 1rem;
.profile-container{
    position: relative;
}
& > h2 {
    display: block;
    width: 100%;
}
& > div > img {
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    display: inline;
    margin:0;
    margin-right: 1.5rem;
}
& > div > em {
    display: inline;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);

}
@media ${device.tablet}{
    max-width: 78%;
}
@media ${device.desktop}{
    max-width: 58%;
}
@media ${device.desktopL}{
    max-width: 78rem;
}
`

const componentMapping = {
    fullWidthImg: (content) => <FullImage content={content} />,
    img: (content) => <Image content={content} />,
    title: (content) => <Title content={content} />,
    text: (content) => <Text content={content} />,
    sectionTitle: (content) => <SectionTitle content={content} />,
    comment: (content) => <Comment content={content} />

}
//{Object.keys(post).map( key => componentMapping[key](post[key].content))}
function BlogPost({ post }) {
    return (
        <div style={{marginTop: '5rem', marginBottom: '4rem'}}>
            {post.map( item => componentMapping[item.type](item.content))}
        </div>
    )
}

export default BlogPost
