import React from 'react'
import styled from 'styled-components'
import { SectionWrapper } from './sectionWrapper'
import TestImg from '../assets/logo-black.png'
import ByteEraVideo from '../assets/byteera video.mp4'
import ByteEraVideoWebm from '../assets/byteera-video.webm'
import { device } from '../utils/device'
import Button from '../components/Button'

const StyledWrapper = styled.div`
    margin: 1rem;
    width: 65%;
    display: inline-block;
    @media (max-width: 425px){
        mergin-top: 4rem;
    }
`

const SectionImg = styled.video`
  display: none;

  @media ${device.tablet}{
  display: inline-block;
  margin-right: 3rem;
  padding: 7rem 0;
  width: 60%;
  max-width: 20rem;
  box-sizing: border-box;
}
`
const AltLayout = styled(myContainer)`
    width: 100%;
    .text__container{
        margin-top: 70px;
    }
    .text__container , .video__container{
        position: relative;
        display: block;
        width: 100%;
        padding: 1.5rem;
    }
    .video__container > video{
        box-sizing: content-box;
        width: 100%;
    }
@media ${device.tablet}{
    width: 100%;
    position: relative;
    display: block;
    height: 100vh;
    .text__container{
        width: 57%;
        z-index: 3;
        display: inline;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 1.5rem;
        margin: 0;
    }
    .text__container > p {
        max-width: 550px;
    }
    .video__container {
        display: inline;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width:43%;
        z-index: 2;
    }
}
  

`
function myContainer({className}){
    return (
        <section className={className}>
        <div className='text__container'>
        <h1 style={{ marginBottom: '1.5rem'}}>We help people and businesses succeed online</h1>
        <p style={{fontSize: '140%', marginBottom: '2rem'}}>Through creative thinking, innovative design and accurate analytics</p>
        <Button to='/contact' type='secondary'>Let's Work Together &rarr;</Button>
        </div>
        <div className='video__container'>
        <video loop autoPlay muted >
            <source src={ByteEraVideoWebm}Webm type="video/webm" />
            <source src={ByteEraVideo} type="video/mp4" />
            Video not supported
        </video>
        </div>
        </section>
    )
}
/**
 * <SectionWrapper>
        <StyledWrapper>
            <h1>Taking creative ideas to the next level.</h1>
            <p>Byte-era is a <span>branding and digital agency that makes an impression. </span></p>
        </StyledWrapper> 
        <video loop autoPlay muted >
            <source src={ByteEraVideo} type="video/mp4" />
            Video not supported
        </video>
    </SectionWrapper>
 * 
 */
function Statement() {
    return (
    <AltLayout />
    )
}

export default Statement
