import React from 'react'
import styled from 'styled-components'
import { SectionWrapper, SectionInfo } from './sectionWrapper'
import { device } from '../utils/device'
import {LogoBOSVG} from '../assets/beauty-originz'
import { LogoSVGAlafea} from '../assets/alafea-institute'
import { LogoSVG } from '../assets/maphosa-and-associates'
import { LogoSVGDephlex} from '../assets/dephlex-creatives'
import { LogoSVGLihles} from '../assets/lihles-beauty-palace'
import AfricanTaste from '../assets/african taste.svg'
import Button from './Button'


const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0.8rem;
    margin-right: 2rem;
`
const ClientConationer = styled.div`
  padding-bottom: 3rem;
  border-bottom: 1px solid black;
  & > ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin-left: 0;
    & > li{
        display: list-item;
        text-align: center;
        margin-bottom: 50px;
        width: 33%;
        & > img {
            max-width: 22%;
        }
    }
  }
  @media ${device.laptopL} {
    & > ul > li {
        width: 20%;
    }
   }
`

export default function Clients({hideCTA}){
    let clientCTA = (<div style={{marginTop: '3rem'}}>
    <Button type='primary' to='/work'>All Projects</Button>
  </div>)
    return (
      <SectionWrapper>
        <SectionInfo>
          <span>
            <div>S</div>
            <div>R</div>
            <div>E</div>
            <div>N</div>
            <div>T</div>
            <div>R</div>
            <div>A</div>
            <div>P</div>
          </span>
        </SectionInfo>
        <FlexColumnContainer>
        <div style={{display: 'flex', width:'100%',margin: '50px 0' }}><h1>Our Clients</h1></div>
          <ClientConationer>
            <ul>
              <li>
                <img src={LogoSVGAlafea} alt='client logo'/>
              </li>
              <li>
                <img src={LogoBOSVG} alt='client logo'/>
              </li>
              <li>
                <img src={LogoSVG} alt='client logo'/>
              </li>
              <li>
                <img src={LogoSVGDephlex} alt='client logo'/>
              </li>
              <li>
                <img src={LogoSVGLihles} alt='client logo'/>
              </li>
              <li>
                <img src={AfricanTaste} alt='client logo'/>
              </li>
            </ul>
          </ClientConationer>
          {hideCTA? null: clientCTA}
          
        </FlexColumnContainer>
      </SectionWrapper>
    )
  }