import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SectionWrapper } from '../components/sectionWrapper'
import { Navigation } from '../components/navigationWhiteBG'
import Footer from '../components/footer'
import Clients from '../components/clients'
import { projects, categories, sectors } from '../utils/projectInfo'
import styled from 'styled-components'
import { device } from '../utils/device'

import ImgSmall from '../assets/working1-small.jpg'
import ImgMedium from '../assets/working1-medium.jpg'

import { Helmet } from 'react-helmet'
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);


function FilterableProjectList ({projects, filters}){
    //filtered list === filter where item.categories.indexOf(filter) !== -1
    if(filters.length === 0){
        return <ProjectList list={projects} />
    }else{
        const filteredList = projects.filter((item)=>{return item.categories.indexOf(filters[0]) !== -1 })
        return(
            <ProjectList list={filteredList} />
        )
    }
    
}
function ProjectList({list}){

    return(
    <div style={{display: 'flex',width: '100%', flexWrap: 'wrap',justifyContent: 'space-around', boxSizing: 'border-box',margin: '0 auto'}}>
        {list.length > 0? list.map((item)=>{
        return <StyledProjectCard key={item.title} project={item} />}): 'No projects in that category at the moment.'}
    </div>
    )
}
function ProjectCard({className, project}){
    return (
    <div className={className}>
        <Link to={`./work/${project.projectUrl}`} >
        <div style={{minWidth: '300px'}}>
        <div>{project.client}</div>
        <div><h3 ><span className={'underline'}>{project.title}</span></h3></div>
        <div><p>{project.categories.map(item => <span>#{item} </span>)}</p></div>
        </div>
        <GradientCard />
        <img alt='project card' src={project.thumbnailUrl} srcSet={project.srcSet} />
        </Link>
    </div>)
}
const GradientCard = styled.span`
position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
    pointer-events: none;
`
const StyledProjectCard = styled(ProjectCard)`
& > a {
    width: 100%;
    display: flex;
    min-height: 300px;
    box-sizing: border-box;
    position: relative;
}


    & > a > div{
        width:calc(100%-30px);
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 0 0 30px 30px;
        color:white;
        z-index:5;
        & > div > h4,h3{
            color : white;
            margin:0;
        }
    }
    & > a > img  {
        position: absolute;
        width: 100%;
        height:100%;
        object-fit: cover;
        z-index: -1;
        top:0;
        left:0;
    }
    @media ${device.mobileS}{
        width: 100%;
        margin: 1rem 1rem;
    }
    @media ${device.tablet}{
        width: calc(50% - 4rem);
        margin: 1rem;
    }
&:hover {
    .underline:before,
    .underline:after {
        width: 100%;
        opacity: 1;
      }
}
.underline {
    position: relative;
    transition: all 0.3s ease-in;
  }
  .underline::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 0px;
    height: 2px;
    margin: 5px 0 0;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.75s;
    opacity: 1;
    background-color: white;
  }

`

function FilterButton({className, active, category, onToggleCategoryFilter}){
    return(
    <li style={{margin: '0.4rem 0'}} onClick={()=> onToggleCategoryFilter(category)}><span className={className}>{category}</span></li>
    )
}
const StyledFilterButton = styled(FilterButton)`
    background-image: linear-gradient(45deg, rgba(251,86,7,1) 0%, rgba(255,0,110,1) 51%, rgba(131,56,236,1) 100%);
  background-repeat: no-repeat;
  background-position: 0 100%;
    color: #777;
    cursor: pointer;
    transition: background-size 0.3s ease-in;
    ${props => props.active? 'background-size: 100% 0.2em;':'background-size: 0% 0.2em;'};
    &:hover {
        background-size: 100% 0.2em;
        
    }
`
const HeaderSection = styled.div`
margin-top: 6rem;
display: flex;
width: 100%;
padding: 1rem 2rem;
align-items: center;
min-height: 70vh;
position: relative;


@media ${device.mobileS}{
    & > h1 {
        font-size: 350%;
        max-width: 80%;
    }
    & > img {
        position: absolute;
        right: 10%;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
    }
}
@media ${device.tablet}{
    & > h1 {
        font-size: 450%;
        max-width: 60%;
    }
    & > img {
        position: absolute;
        right: 17%;
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
    }
}

@media ${device.desktop}{
    & > h1 {
        font-size: 450%;
        max-width: 60%;
    }
    & > img {
        position: absolute;
        right: 30%;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
    }
}
`
export default function Work() {
    const [inProp, setInProp] = useState(false);
    const [activeCategoryFilter, setActiveCategoryFilter] = useState([])

    const clearFilter = ()=>{
        setActiveCategoryFilter([])
    }
    const toggleCategoryFilter = (filtername)=>{
        console.log('category Filter', filtername)
        if(activeCategoryFilter.length === 0){
            setActiveCategoryFilter([filtername])            
            console.log('activeCategoryFilter', activeCategoryFilter)

        }else if(activeCategoryFilter[0] === filtername ){
            setActiveCategoryFilter([])
            console.log('activeCategoryFilter', activeCategoryFilter)

        }else{
            setActiveCategoryFilter([filtername])
            console.log('activeCategoryFilter', activeCategoryFilter)
        }
        
    }
    useEffect(() => {
        setInProp(!inProp)
        
      }, [])
    return (
        <>
        <Helmet>
    <meta charSet="utf-8" />
      <title>Our Work | Byte-era Agency</title>
    </Helmet>
        <Navigation disableTransparency={true}/>
        <CSSTransition timeout={500} in={inProp} classNames="my-node">
         <div> 
        <SectionWrapper flexColumn={true}>
        
            <HeaderSection>
            <h1>We're proud of our work</h1>
            <img src={ImgSmall} srcSet={`${ImgSmall} 300w, ${ImgMedium} 768w, ${ImgMedium} 1280w`} alt='text2'/>
            </HeaderSection>
            <div style={{display:'flex', width: '100%',padding: '1rem 2rem', justifyContent: 'space-around'}}>
                <div>
                    <h4>Filters </h4>
                    <span style={{cursor: 'pointer'}} onClick={()=> clearFilter()}>Clear Filters</span>
                </div>
                <div>
                    <h4>Industry:</h4>
                    <ul style={{listStyle: 'none', margin: '0'}}>
                    {sectors.map(item=><StyledFilterButton key={item} active={(item===activeCategoryFilter[0])} category={item} onToggleCategoryFilter={toggleCategoryFilter} />)}
                    </ul>
                </div>
                <div>
                    <h4>Service:</h4>
                    <ul style={{listStyle: 'none', margin: '0'}}>
                        {categories.map(item=><StyledFilterButton key={item} active={(item===activeCategoryFilter[0])} category={item} onToggleCategoryFilter={toggleCategoryFilter} />)}
                    </ul>
                </div>
            </div>
            <div style={{width: '100%'}}>
                <FilterableProjectList projects={projects} filters={activeCategoryFilter}/>
            </div>
            <Clients hideCTA={true}/>
        </SectionWrapper>
        <Footer />
            </div>
            </CSSTransition>
        </>
    )
}
