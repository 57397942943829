import React from "react"
import styled from 'styled-components'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faFacebookF, faLinkedinIn, faBehance, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { SectionWrapper } from './sectionWrapper'
const FooterWrapper = styled.footer`
  background-color: black;
  width: 100%;
  color: white;
`
export default function Footer(){
  return (
    <FooterWrapper>
      <SectionWrapper>
      <div  style={{display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap'}}>
        <div style={{width: '50%', boxSizing: 'border-box', padding: '2rem', minWidth: '350px'}}>
          <div><h2 style={{color: 'white'}}>Ready To Work <br/>With Us ?</h2></div>
          <div style={{marginTop: '3rem'}}><Button to='/contact' type='tertiary'>info@byte-era.com</Button></div>
        </div>
        <div style={{width: '50%',boxSizing: 'border-box', padding: '2rem', minWidth: '350px'}}>
          <div><h2 style={{color: 'white'}}>Let's Digitize</h2></div>
          <div style={{marginTop: '3rem'}}>
            <p>50 Zambezi Road</p>
            <p>Lusaka, Zambia</p>
            <p>Mobile: <a style={{textDecoration: 'none'}} href="tel:+260976969250"> +260 976969250 </a></p>
          </div>
        </div>
      </div>
      </SectionWrapper>
      <div style={{margin: '0 auto', paddingTop: '3rem' ,paddingBottom: '2rem', maxWidth: '78rem', width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        <div style={{paddingLeft: '2rem'}}><Link to='/contact'>Collab &bull; Contact</Link></div>
        <div style={{paddingRight: '2rem'}}>
          <span style={{paddingRight: '0.5rem'}}><a href='https://www.instagram.com/byte.era.agency/'><FontAwesomeIcon icon={faInstagram} /></a></span>
          <span style={{paddingRight: '0.5rem'}}><a href='https://www.behance.net/Byte-era'><FontAwesomeIcon icon={faBehance} /></a></span>
          {/*<span style={{paddingRight: '0.5rem'}}><FontAwesomeIcon icon={faLinkedinIn} /></span> */}
          <span style={{paddingRight: '0.5rem'}}><a href='https://www.facebook.com/Byte-era-Agency-105812617967051'><FontAwesomeIcon icon={faFacebookF} /></a></span>
          </div>
      </div>
    </FooterWrapper>
  )
}
