import React,{ useState, useEffect }from 'react'
import styled from 'styled-components'
import { SectionWrapper, SectionInfo } from '../components/sectionWrapper'
import { Navigation } from '../components/navigationWhiteBG'
import Footer from '../components/footer'

import belief from '../assets/belief.png'
import beliefSmall from '../assets/belief-small.jpg'
import beliefMedium from '../assets/belief-medium.jpg'


import Services from '../components/services'
import Vision from '../components/vision'
import  TeamCarousel from '../components/teamCarousel'
import  MobileTeamCarousel from '../components/mobileTeamCarousel'
import Testimonial from '../components/testimonial'
import photoSmall from '../assets/working1-small.jpg'
import photoMedium from '../assets/working1.webp'
import { device } from '../utils/device'
import { projects } from '../utils/projectInfo'
import { MiniCardContainer, StyledMiniProjectCards} from '../components/miniProjectCards'
import Button from '../components/Button'
import { Helmet } from 'react-helmet'
import { CSSTransition } from 'react-transition-group';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const Container = styled.div`
  margin-left: 0.8rem;
  margin-right: 2rem;
  display: 'flex', 
  flex-direction: column;
  width: 100%;
`
const AboutUs = styled.div`
  margin-top: 1rem;
  font-size: 200%;
  @media ${device.tablet}{
    max-width: 55%;
    margin: 1.5rem;
  }
  & > p {
      padding: 1rem;
  }
  `

const ResponsiveContainer = styled.section`
margin: 0 auto;
margin-bottom: 30px;
max-width: 78rem;
display: flex;
width: 100%;
flex-direction: column;
@media ${device.tablet}{
    flex-direction: row;
  }
position: relative;
align-items: center;
justify-content: center;
`
const FounderStatement = styled.div`
  margin: 1.5rem;
  margin-top: 0;
  & > p {
    font-size: 120%;
  }
  & > span{
      font-size: 80%;
      font-weight: 700;
      text-transform: uppercase;
  }
`
const CarouserlContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 80vh;
  box-sizing: border-box;
`

const HeadingCarousel = styled.section`
display: flex;
justify-content: center;
flex-direction: column;
position: relative;
max-width: 78rem;
width: 90%;
margin: 1.5rem auto;
margin-top: 70px;
box-sizing: border-box;
height: 100%;
& > h1 {
    font-size: 350%;
    position: relative;
    width: 100%;
    margin-left: -10px;
    z-index: 3;
    padding: 6rem 0;
}
@media ${device.tablet}{
    & > h1 {
        font-size: 450%;
        position: relative;
        width: 70%;
        margin-left: -20px;
        z-index: 3;
        padding: 6rem 0;
    }
  }
  

  .big-header__visual{
      width: 100%;
      height: 100%;
      position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    box-sizing: border-box;
  }
 .is-background {
    width: 100%;
    height: 100%;
    position: relative;
box-sizing: border-box;
  }
  .background__img {
    background-image: url(${photoSmall});
    @media ${device.tablet}{
      background-image: url(${photoMedium});
    }
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
box-sizing: border-box;
opacity:0.9;
  }
`

function Agency() {
    const [width, setWidth] = useState(window.innerWidth);
    const [inProp, setInProp] = useState(false);
  const breakpoint = 768;
  let brandingProjects = projects.filter(item => item.categories.indexOf('branding') !== -1)
    let trimmedResults = brandingProjects.slice(0,3)
  useEffect(() => {
      setInProp(!inProp)
      
    }, [])
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
        let desktopCarousel = (<CarouserlContainer>
            <div style={{position:'relative',width: '100%', boxSizing: 'border-box'}}>
            <div style={{position:'absolute', top: '50%', left: '3rem',zIndex: '30', transform: 'translateY(-50%)',width: '40%'}}><h1>Go further with our team of creative minds.</h1></div>
            <TeamCarousel />
            </div>
        </CarouserlContainer>)

        let mobileCarousel = (<CarouserlContainer>
            <div style={{position:'relative',width: '100%', boxSizing: 'border-box'}}>
            <h1 style={{padding: '1rem', marginBottom: '1.5rem'}}>Go Further with our team of creative minds.</h1>
            <MobileTeamCarousel />
            </div>
        </CarouserlContainer>) 
    return (
        <>
         <Helmet>
    <meta charSet="utf-8" />
      <title>Agency | Byte-era Agency</title>
    </Helmet>
        <Navigation disableTransparency={true}/>
        <CSSTransition timeout={500} in={inProp} classNames="alert">
         <div> 
        <HeadingCarousel>
            <h1>A creative agency tailored to your exact needs.</h1>
            <div className={'big-header__visual'}>
                <div className={'is-background'}>
                    <div className={'background__img'}></div>

                </div>
                
            </div>
        </HeadingCarousel>
        </div>
        </CSSTransition>
        <CSSTransition timeout={500} in={inProp} classNames="my-node">
         <div> 
        <ResponsiveContainer>
            <AboutUs><p>
            Byte-era was born from our desire to help brands and people thrive in a digital world through creative solutions.
                </p>
            </AboutUs>
            <FounderStatement>
                <p>We strive to build lasting partnerships with our customers through beautiful and impactful work.</p>
                <span>Tabo Mbaleni & Grace Tshuma <br/>Founders</span>
            </FounderStatement>
        </ResponsiveContainer>
        <SectionWrapper>
            <SectionInfo>
            <span>
            <div>F</div>
            <div>E</div>
            <div>I</div>
            <div>L</div>
            <div>E</div>
            <div>B</div>
        </span>
        </SectionInfo>
            <Container>
            <img style={{width: '100%', marginBottom: '0', filter: 'grayscale(100%)'}} src={beliefSmall} srcSet={`${beliefSmall} 300w, ${beliefMedium} 768w, ${belief} 1280w`} alt='testing'/>
            </Container>
        </SectionWrapper>
        { width < breakpoint? mobileCarousel: desktopCarousel }
        <ResponsiveContainer>
            <AboutUs><p>
            A team of talented and technical creatives based in Lusaka.
                </p>
            </AboutUs>
            <FounderStatement>
                <p>Driven by passion, each of our team members brings you a little more than their skills thanks to a creative and collaborative process.</p>
            </FounderStatement>
        </ResponsiveContainer>
        <Vision />
        <Services/>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {trimmedResults.map(item => <StyledMiniProjectCards key={item.title} to={`/work/${item.projectUrl}`} project={item} />)}
            </MiniCardContainer>
        </div>
        <Testimonial />
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
        <div style={{marginTop: '3rem', marginBottom: '6rem'}}>
            <Button type='primary' to='/work'>All Projects</Button>
          </div>
          </div>
        <Footer />
        </div>
        </CSSTransition>
        </>
    )
}

export default Agency
