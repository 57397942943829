import React from 'react'
import { Navigation } from '../components/navigationWhiteBG'
import Footer from '../components/footer'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function TypeForm(){
    return (<ReactTypeformEmbed url="https://tmbaleni.typeform.com/to/gBD0MGfe" />)
  }

function Contact(){
    return (
        <>
         <Helmet>
    <meta charSet="utf-8" />
      <title>Contact Us | Byte-era Agency</title>
    </Helmet>
        <Navigation disableTransparency={true}/>
        <div style={{zIndex: '1',height: '100vh'}}>
        <TypeForm />
        </div>
        <Footer />
        </>

    )
}

export default Contact
