import React from 'react'
import { SectionWrapper } from './sectionWrapper'
import styled from 'styled-components'
import { device } from '../utils/device'

const StyledHeading = styled.h1`
  color: white;
  padding:  2rem 2rem;
  text-align: center;
  max-width: 78rem;
  @media ${device.laptopL}{
    padding: 6rem 3rem 0rem;
  }
`

export default function Vision (){
    return (
      <div style={{display: 'flex', width: '100%', backgroundColor: 'black'}}>
        <SectionWrapper>
          <div style={{minHeight: '60vh', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
          <StyledHeading>
          Our brand support is 360 <br/> with goal driven creativity at its core.
          </StyledHeading>
          </div>
        </SectionWrapper>
      </div>
    )
  }