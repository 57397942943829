import React from 'react'
import { Navigation } from '../../components/navigationWhiteBG'
import { SectionWrapper } from '../../components/sectionWrapper'
import { projects } from '../../utils/projectInfo'
import { MiniCardContainer, StyledMiniProjectCards, CapabilitiesListContainer } from '../../components/miniProjectCards'
import Footer from '../../components/footer'
import Clients from '../../components/clients'
import StyledServicesProjectCard from '../../components/servicesCards'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function AppDevelopment() {
    let appDevProjects = projects.filter(item => item.categories.indexOf('app-development') !== -1)
    let trimmedResults = appDevProjects.slice(0,3)
    let appDesignCards = [
        {title: 'Android App', services:['Simple native Android app'], price: 'K20,000', dark: false},
        {title: 'React Native App', services:['IOS and Android apps built with React Native'], price: 'K25,000',dark: true},
        {title: 'React Native Ecommerce App', services:['Ecommerce IOS and Android apps built with React Native'], price: 'K30,000', dark: false}]
    
    return (
        <>
        <Navigation disableTransparency={true}/>
        <SectionWrapper flexColumn={true}>
            <div style={{width: '100%', margin: '1rem', minHeight: '100%',position: "relative"}}>
            <div style={{marginTop: '10rem', padding: '1rem'}}>
                <div style={{width: '100%'}}>
                <h1 style={{display: 'flex', width: '100%'}}>App Development</h1>
                <p style={{maxWidth:'830px'}}>
                Our team builds mobile and web applications using Swift, Kotlin and ReactJS for innovative 
                brands and companies across Africa. </p>
                </div>
            </div>
            </div>
        </SectionWrapper>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {trimmedResults.map(item => <StyledMiniProjectCards key={item.title} to={`/work/${item.projectUrl}`} project={item} />)}
            </MiniCardContainer>
        </div>
        <SectionWrapper>
        <div style={{margin: '1rem'}}>
                <h1>Approach</h1>
                <p>We always put ourselves in the end-users shoes to empathize with them and build better apps. The process is very collaborative by design inorder
                      to achieve the most cohesive apps.
                </p>
            </div>
        </SectionWrapper>
        <div style={{width:'100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{margin:'1rem'}}>
            <h1>Process</h1>
            <div>
                <p>We believe in order to be successful you need extensive preparation in order to deliver authentic products. This is why we 
                dedicate significant attention to research, architecture and prototyping.
                </p>
                </div>
                <div>
                    <h3>1. Gathering requirements</h3>
                    <p>We perform extensive research in order to gain a clear vision of the required functionality and visual aesthetic.
                </p>
                </div>
                <div>
                    <h3>2. Define MVP and roadmap</h3>
                    <p>Once we have a solid vision of the ideal end product and define our roadmap, we prioritize the main features into 
                        the MVP and create a plan for secondary features.
                </p>
                </div>
                <div>
                    <h3>3. Design data structure and API</h3>
                    <p>We design and build back-end systems that enable a smooth user experience.If you have an existing back-end, we will try our best to work with it.
                </p>
                </div>
                <div>
                    <h3>4.  Proof of concept</h3>
                    <p>Next comes building a proof of concept prototypes, this allows us to verify our assumptions to avoid potential pitfalls.
                </p>
                </div>
                <div>
                    <h3>5. Develop the app</h3>
                    <p>Lastly, we build app functionality iteratively, ensuring we are moving fast while maintaining a solid architecture throughout.
                </p>
                </div>
                
            </div>
            
        </div>
        <div style={{width:'100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{padding: '1rem'}}>
                <h1>Our Packages</h1>
                <p>We have proven and effective packages to meet your marketing needs. With hundreds of new mobile applications being built everyday, it’s imperative that 
                    you get the right App developer hired at the competitive app development packages. We are also helping businesses to get their apps noticed in the vast sea of mobile applications and promoting them on various platforms for the maximum benefit.
                </p>
            </div>
        </div>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {appDesignCards.map(item => <StyledServicesProjectCard key={item.title} title={item.title} services={item.services} price={item.price} dark={item.dark} />)}
            </MiniCardContainer>
        </div>
        <Clients />
        <Footer />
        </>
    )
}

export default AppDevelopment
