import React from 'react'
import { Navigation } from '../../components/navigationWhiteBG'
import { SectionWrapper } from '../../components/sectionWrapper'
import Clients from '../../components/clients'
import { projects } from '../../utils/projectInfo'
import { MiniCardContainer, StyledMiniProjectCards, CapabilitiesListContainer } from '../../components/miniProjectCards'
import Footer from '../../components/footer'
import StyledServicesProjectCard from '../../components/servicesCards'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function GraphicDesign() {
    let socialMediaProjects = projects.filter(item => item.categories.indexOf('UI/UX-design') !== -1)
    let trimmedResults = socialMediaProjects.slice(0,3)
    let graphicDesignCards = [
        {title: 'Poster/Flyer Design', services:['A simple digital poster'], price: 'K600', dark: false},
        {title: 'Youtube Cover Design', services:['Youtube covers and thumbnail Design'], price: 'K600',dark: true},
        {title: 'Billboard Design', services:['Roadside billboard design'], price: 'K2500', dark: false}]
    

    return (
        <>
        <Navigation disableTransparency={true}/>
        <SectionWrapper flexColumn={true}>
            <div style={{width: '100%', margin: '1rem', minHeight: '100%',position: "relative"}}>
            <div style={{marginTop: '10rem', padding: '1rem'}}>
                <div style={{width: '100%'}}>
                <h1 style={{display: 'flex', width: '100%'}}>Graphic Design</h1>
                <p style={{maxWidth:'830px'}}>Our team designs engaging visuals with consistent branding from flyers, posters, business cards, billboards to catalogues and brochures
                 that drive actions and increases conversions. </p>
                </div>
            </div>
            </div>
        </SectionWrapper>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {trimmedResults.map(item => <StyledMiniProjectCards key={item.title} to={`/work/${item.projectUrl}`} project={item} />)}
            </MiniCardContainer>
        </div>
        <SectionWrapper flexColumn={true}>
        <div style={{margin: '1rem'}}>
                <h1>Approach</h1>
                <p>We extend your product design departments teams. We provide ongoing graphic design consultation
                     and interactive design improvements. The process is very collaborative by design in order
                      to achieve the most cohesive product.
                </p>
            </div>
        </SectionWrapper>
        <div style={{width:'100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{margin:'1rem'}}>
            <h1>Process</h1>
                <div>
                    <h3>1. Dicovery</h3>
                    <p>Gathering requirements: assess current pain points, project goals, user personas,
                        key use cases and industry best practices.
                    </p>
                </div>
                <div>
                    <h3>2. Visual concept and style application</h3>
                    <p>Visual direction, concepts of key screens, high-fidelity mockups.                        
                    </p>
                </div>
                <div>
                    <h3>3. Delivery and guidlines</h3>
                    <p> We hand finalize the details and make sure you know the best usecases for your designs and some rules to follow to maximize engagement.               
                    </p>
                </div>
            </div>
        </div>
        <div style={{width:'100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{margin: '1rem'}}>
                <h1>What we do</h1>
                <p>We help companies achieve quantitifiable business goals through creative and captivating experiences 
                    that sets them apart from the competition.
                </p>
            </div>
                <CapabilitiesListContainer>
                <div>
                        <h3>Print Design</h3>
                        <ul>
                            <li>Posters/Flyers</li>
                            <li>Brochures</li>
                            <li>Catalogues</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Other</h3>
                        <ul>
                            <li>Youtube cover/thumbnail design</li>
                            <li>Billboard design</li>
                            <li>Print Ad design</li>
                        </ul>
                    </div>
                </CapabilitiesListContainer>
            </div>
            <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{padding: '1rem'}}>
                <h1>Our Packages</h1>
                <p>Our approach focuses on building trust and standing out in a crowded world.
                    We make sure the identity works well in any environment and is visually consistent across all mediums.
                </p>
            </div>
        </div>
            <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {graphicDesignCards.map(item => <StyledServicesProjectCard key={item.title} title={item.title} services={item.services} price={item.price} dark={item.dark} />)}
            </MiniCardContainer>
        </div>
        <Clients />
        <Footer />
        </>
    )
}

export default GraphicDesign
