import React from 'react'
import { Navigation } from '../../components/navigationWhiteBG'
import { SectionWrapper } from '../../components/sectionWrapper'
import Footer from '../../components/footer'
import styled  from 'styled-components'
import {Link}  from 'react-router-dom'
import { device } from '../../utils/device'
import Clients from '../../components/clients'
import { projects } from '../../utils/projectInfo'
import StyledServicesProjectCard from '../../components/servicesCards'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const GradientCard = styled.span`
position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    pointer-events: none;
`



const MiniProjectCards = ({className, to, project}) => {
    return(
        <div className={className}>
        <Link to={to}>
            <div className={'mini-cards'}>
                <div className={'mini-content'}>
                <div>{project.client}</div>
                <div>
                    <h3 style={{marginBottom: '6px'}}>{project.title}</h3>
                    <div className={'line'}></div>
                </div>
                <div><h4 style={{marginBottom: '6px'}}>{project.categories.map(item => <span>#{item} </span>)}</h4></div>
                <img className={'mini-img'} alt='project card' src={project.thumbnailUrl} />
                </div>
            </div>
            <GradientCard />
        </Link>
        </div>
    )
}
const MiniCardContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%; 
justify-content: center;
content: '';
margin: 0 auto;
@media ${device.tablet}{
    flex-direction: row;
    width: 100%;
    justify-content: space-around; 
}
`

const StyledMiniProjectCards = styled(MiniProjectCards)`
width: 100%;
box-sizing: border-box;
position: relative;
margin-bottom:  2rem;
@media ${device.tablet}{
    width: 33%;
    margin: 0.6rem;
}
box-sizing: border-box;
&>a:link, &>a:visited,&>a:active{
    text-decoration: none;
    color: black;
}
.line{
    opacity: 0;
    content: '';
    transition: all 0.3s ease;
    z-index: 5;
    height: 2px;
    background-color: white;
    width: 10%;

}
.mini-cards:hover{
    box-shadow: 10px 20px 55px rgba(0,0,0,0.1);
    .line{
        opacity: 1;
        width:100%;
    }
}
.mini-cards{
    width: calc( 100% - 4rem);
    height: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 2rem;
    color: white;
    h3,h4{color:white}
    
    
}
.mini-img {
    position: absolute;
    width: 100%;
    height:100%;
    object-fit: cover;
    z-index: -1;
    top:0;
    left:0;
}
.mini-content{
    padding-top: 3rem;
}

`
const CapabilitiesListContainer = styled.div`
    width:100%;
    display: flex;
    margin: 1rem;
    flex-direction: column;
    &> div{
        padding: 1rem;
        width: 100%;
        ${device.maxTablet}{
            width: 95%;
        }
    }
    @media ${device.tablet}{
        flex-direction: row;
        justify-content: space-around;
    }
`

function Branding() {
    let brandingProjects = projects.filter(item => item.categories.indexOf('branding') !== -1)
    let trimmedResults = brandingProjects.slice(0,3)
    let brandingCards = [
        {title: 'Basic Package', services:['Brand Identity Design', 'Logo Design','Business Card Design'], price: 'K2000', dark: false},
        {title: 'Growth Package', services:['Brand Identity Design','Logo Design','Competitor Analysis','Growth Strategy','Business Card Design'], price: 'K3500',dark: true},
        {title: 'Premium Package', services:['Brand Identity Design','Logo Design','Competitor Analysis','Growth Strategy','Business Card Design','Letterhead Design','Manage targeted ads'], price: 'K8000', dark: false}]
    return (
        <>
        <Navigation disableTransparency={true}/>
        <SectionWrapper flexColumn={true}>
            <div style={{width: '100%', minHeight: '100%',position: "relative"}}>
            <div style={{marginTop: '10rem', padding: '1rem'}}>
                <div style={{width: '100%'}}>
                <h1 style={{display: 'flex', width: '100%'}}>Branding &amp; Strategy</h1>
                <p style={{maxWidth:'830px'}}>Our team creates visual identities and develops winning brand 
                strategies for growing companies and influencers from all corners of the continent</p>
                </div>
            </div>
            
            </div>
        </SectionWrapper>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {trimmedResults.map(item => <StyledMiniProjectCards key={item.title} to={`/work/${item.projectUrl}`} project={item} />)}
            </MiniCardContainer>
        </div>
        <SectionWrapper flexColumn={true}>
            <div style={{padding: '1rem'}}>
                <h1>Approach</h1>
                <p>Our approach focuses on building trust and standing out in a crowded world.
                    We make sure the identity works well in online environment and is visually consistent across all mediums.
                </p>
            </div>
            <div style={{padding: '1rem'}}>
                <h1>What we do</h1>
                <p>The result of our company branding process is a comprehensive brand 
                    guideline and strategies that can be used to design a marketing website, digital campaign or other 
                    design assets like brand illustrations that reflect the new brand and achieve your marketing goals.
                </p>
            </div>
                <CapabilitiesListContainer>
                <div>
                        <h3>Strategy</h3>
                        <ul>
                            <li>Brand audit</li>
                            <li>Audience analysis</li>
                            <li>Competitive review</li>
                            <li>Strategic direction</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Brand Identity</h3>
                        <ul>
                            <li>Visual Identity System</li>
                            <li>Icons &amp; illustration guides</li>
                            <li>Brand application</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Communication Strategy</h3>
                        <ul>
                            <li>Communication style guide</li>
                        </ul>
                    </div>
                </CapabilitiesListContainer>
        </SectionWrapper>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto'}}>>
            <div style={{padding: '1rem'}}>
                <h1>Our Packages</h1>
                <p>Our approach focuses on building trust and standing out in a crowded world.
                    We make sure the identity works well in online environment and is visually consistent across all mediums.
                </p>
            </div>
        </div>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {brandingCards.map(item => <StyledServicesProjectCard key={item.title} title={item.title} services={item.services} price={item.price} dark={item.dark} />)}
            </MiniCardContainer>
        </div>
        <Clients />
        <Footer />
        </>
    )
}

export default Branding
