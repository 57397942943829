import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
//import { colors } from '../utils/colors'

const StyledButton = styled(Button)`
    ${props => props.type === 'primary' && 'margin-top: 1.5rem; text-decoration: none; background: linear-gradient(45deg, rgba(251,86,7,1) 0%, rgba(255,0,110,1) 51%, rgba(131,56,236,1) 100%); color: white !important; padding: 1rem ; box-sizing: border-box;transition: all 0.3s ease; &:hover{box-shadow:10px 20px 55px rgba(152,68,183,0.15);transform: translateY(-5px);}'}
    ${props => props.type === 'secondary' && 'margin-top: 1.5rem; text-decoration: none; background: black; color: white !important; padding: 1rem ; box-sizing: border-box;transition: all 0.3s ease; &:hover{box-shadow:10px 20px 55px rgba(0,0,0,0.1);transform: translateY(-5px);}'}
    ${props => props.type === 'tertiary' && 'margin-top: 1.5rem; text-decoration: none; background: white; color: black !important; padding: 1rem ; box-sizing: border-box; transition: all 0.3s ease; &:hover{box-shadow:10px 20px 55px rgba(0,0,0,0.1); transform: translateY(-5px);}'}

    `

function Button({className, type, to, children}) {
    return (
        <Link className={className} to={to}>
            {children}
        </Link>
    )
}

export default StyledButton
