import React from 'react'
import styled from 'styled-components'
import ByteeraReel from '../assets/Byte-era Reel.mp4'

function MyReel({className}){
    return (
      <video className={className} style={{filter: 'brightness(80%)',display: 'block',width: '100%', margin: '0', padding: '0'}} loop autoPlay muted>
        <source src={ByteeraReel} type='video/mp4' />
      </video>
    )
  }
  const StyledReel = styled(MyReel)`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translate(-50%, -50%);
  `

function Reel() {
    return (
        <section style={{width: '100%', margin: '0', padding: '0'}}>
        <MyReel />
        </section>
    )
}

export default Reel
