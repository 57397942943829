import Typography from "typography"
//import { TypographyStyle, GoogleFont } from 'react-typography'

const typography = new Typography({
    googleFonts: [
        {
          name: 'Ubuntu',
          styles: [
            '700',
          ],
        },
        {
          name: 'Open Sans',
          styles: [
            '400',
            '400i',
            '700',
            '700i',
          ],
        },
      ],
      headerFontFamily: ['Ubuntu', 'sans-serif'],
      bodyFontFamily: ['Open Sans', 'sans-serif'],
      scaleRatio: 3,
      baseLineHeight: 1.5,
      baseFontSize: '16px',
      headerColor: '#000000'
})

export const { scale, rhythm, options } = typography
export default typography