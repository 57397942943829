import React from 'react'
import { SectionWrapper } from './sectionWrapper'
import styled from 'styled-components'
import { device } from '../utils/device'

const StyledHeading = styled.p`
  color: white;
  max-width: 78rem;
  width: 100%;
  margin: 4rem  auto;
  text-align: left;
  font-size: 150%;
  box-sizing: border-box;
  padding: 0 1.5rem;
  @media ${device.tablet}{
    font-size: 150%;
    margin: 8rem  auto;
  }
`

export default function Description (){
    return (
      <div id={'banner'} style={{display: 'flex', width: '100%', backgroundColor: 'black'}}>
        <SectionWrapper style={{marginBottom: '0'}}>
          <StyledHeading>
          Growing an audience, customer base or community online is difficult. We simplify the process into easily executable steps and create engaging content to help you succeed.
          </StyledHeading>
        </SectionWrapper>
      </div>
    )
  }