import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './pages/index'
import Contact from './pages/contact'
import Work from './pages/work'
import Agency from './pages/agency'
import Branding from './pages/agency/branding'
import Blog from './pages/blog'
import typography from './utils/typography'
import { TypographyStyle, GoogleFont } from 'react-typography'
import SocialMedia from './pages/agency/socialMedia';
import WebDesign from './pages/agency/webDesign'
import AppDevelopment from './pages/agency/appDevelopment'
import GraphicDesign from './pages/agency/graphicDesign'
import ScrollToTop from './components/scrollToTop'
import { Helmet } from 'react-helmet'
import GlobalStyle from './utils/globalStyles'
import './App.css'


function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <TypographyStyle typography={typography} />
    <GoogleFont typography={typography} />
    <Helmet>
    <meta charSet="utf-8" />
      <title>Byte-era Agency</title>
    </Helmet>
    <Switch>
          <Route exact path="/agency">
            <Agency />
          </Route>
          <Route path="/agency/branding">
            <Branding />
          </Route>
          <Route path="/agency/social-media">
            <SocialMedia />
          </Route>
          <Route path="/agency/web-design">
            <WebDesign />
          </Route>
          <Route path="/agency/app-development">
            <AppDevelopment />
          </Route>
          <Route path="/agency/graphic-design">
            <GraphicDesign />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route exact path="/work">
            <Work />
          </Route>
          <Route path="/work/:articleName">
            <Blog />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
