import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    ::-webkit-scroll-behavior: smooth;
    ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: linear-gradient( rgba(251,86,7,1) 0%, rgba(255,0,110,1) 51%, rgba(131,56,236,1) 100%); 
    }
  }
  a:link,a:visited,a:active{
    color: inherit;
  }

  .item-enter{
    opacity: 0;
  }
  .item-enter .item-enter-active{
    opacity: 1;
    transition: opacity 300ms;
  }
  .item-enter-done{
    opacity: 1;

  }
  
`;
export default GlobalStyle;