import React from 'react'
import styled from 'styled-components'
import { SectionWrapper, SectionInfo } from './sectionWrapper'
import { device } from '../utils/device'
import { Link } from 'react-router-dom'
const ServicesCardContainer = styled(Link)`
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
    text-decoration: none;
    color: black;
    transition: all 0.3s ease-in;
    &:hover {
      transform: translateY(-12px);
      box-shadow: 10px 20px 55px rgba(152,68,183,0.15);
      cursor: pointer;
    }
    
`
const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => props.justifyContent};
  flex-wrap: wrap;
  @media ${device.tablet} {
    & > a {
        width: 30%;
    flex-wrap: no-wrap;
  }
}
`
const ServicesContainer = styled.div`
  margin-left: 0.8rem;
  margin-right: 2rem;
  display: 'flex', 
  flex-direction: column;
  width: 100%;
`

export default function Services(){
    return (
      <>
      <SectionWrapper>
        <SectionInfo>
        <span>
            <div>G</div>
            <div>N</div>
            <div>I</div>
            <div>T</div>
            <div>E</div>
            <div>K</div>
            <div>R</div>
            <div>A</div>
            <div>M</div>
        </span>
        </SectionInfo>
        <ServicesContainer>
        <div style={{display: 'flex', width:'100%',marginBottom: '50px', marginTop: '50px'}}><h1>Our Services</h1></div>
          <FlexContainer justifyContent='space-between'>
            <ServicesCardContainer to='/agency/branding' className='services__card--container'>
              <div className='services__card--title'><h3>Branding + Strategy</h3></div>
              <p>We create user centered visual identities and develop brand strategies for growing startups.
                <span> &rarr;</span>
              </p>
            </ServicesCardContainer>
            <ServicesCardContainer to='/agency/social-media' className='services__card--container'>
              <div className='services__card--title'><h3>Social Media Marketing</h3></div>
              <p>We help your brand consistently stand out from the crowd through engaging content and amazing visuals. &rarr;
              </p>
            </ServicesCardContainer>
            <ServicesCardContainer to='/agency/graphic-design' className='services__card--container'>
              <div className='services__card--title'><h3>Graphic Design</h3></div>
              <p>We create engaging marketing material from flyers, posters, business cards, billboards to catalogues and brochures. &rarr;
              </p>
            </ServicesCardContainer>
          </FlexContainer>
        </ServicesContainer>
      </SectionWrapper>
      <SectionWrapper>
        <SectionInfo>
          <span>
                <div>T</div>
                <div>C</div>
                <div>U</div>
                <div>D</div>
                <div>O</div>
                <div>R</div>
                <div>P</div>
            </span>
        </SectionInfo>
        <ServicesContainer >
            <FlexContainer  justifyContent='space-evenly'>
                <ServicesCardContainer to='/agency/web-design' className='services__card--container'>
                <div className='services__card--title'><h3>Website Design</h3></div>
                <p>We create marketing websites and landing pages that help to tell brand stories, increase conversion and build trust. &rarr;
              </p>
                </ServicesCardContainer>
                <ServicesCardContainer to='/agency/app-development' className='services__card--container'>
                <div className='services__card--title'><h3>App Development</h3></div>
                <p>We build mobile and web apps with the latest innovations that your users will love. &rarr;
                </p>
                </ServicesCardContainer>
            </FlexContainer>
        </ServicesContainer>
    </SectionWrapper>
    </>
    )
  }
