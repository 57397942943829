import React from 'react'
import { Navigation } from '../../components/navigationWhiteBG'
import { SectionWrapper } from '../../components/sectionWrapper'
import Footer from '../../components/footer'
import { projects } from '../../utils/projectInfo'
import { MiniCardContainer, StyledMiniProjectCards, CapabilitiesListContainer } from '../../components/miniProjectCards'
import Clients from '../../components/clients'
import StyledServicesProjectCard from '../../components/servicesCards'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function SocialMedia() {
    let socialMediaProjects = projects.filter(item => item.categories.indexOf('social-media') !== -1)
    let trimmedResults = socialMediaProjects.slice(0,3)
    let brandingCards = [
        {title: 'Basic Package', services:['1 Custom Post per Week on IG + Facebook ','1 Story Post per week', 'Monthly engagement report','Manage targeted ads'], price: 'K2000', dark: false, perMonth: true},
        {title: 'Growth Package', services:['2 Custom Posts per Week on IG + Facebook','2 Story Posts per week','Weekly engagement report','Reply to comments','Manage targeted ads'], price: 'K3000',dark: true, perMonth: true},
        {title: 'Premium Package', services:['5 Custom Posts per Week on IG + Facebook + LinkedIn ','5 Story Posts per week','Weekly engagement report','Reply to comments','Manage targeted ads', 'Advanced analytics', 'Influencer marketing'], price: 'K8000', dark: false, perMonth: true}]
    return (
        <>
        <Navigation disableTransparency={true}/>
        <SectionWrapper flexColumn={true}>
            <div style={{width: '100%', margin: '1rem', minHeight: '100%',position: "relative"}}>
            <div style={{marginTop: '10rem', padding: '1rem'}}>
                <div style={{width: '100%'}}>
                <h1 style={{display: 'flex', width: '100%'}}>Social Media Management</h1>
                <p style={{maxWidth:'830px'}}>Our team helps your brand consitently stand out from the crowd on and grow your 
                customer base on multiply platforms like Instagram, Facebook and Twitter through engaging content and amazing 
                great visuals.</p>
                </div>
            </div>
            </div>
        </SectionWrapper>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {trimmedResults.map(item => <StyledMiniProjectCards key={item.title} to={`/work/${item.projectUrl}`} project={item} />)}
            </MiniCardContainer>
        </div>
        <SectionWrapper flexColumn={true}>
            <div style={{padding: '1rem'}}>
                <h1>Approach</h1>
                <p>We believe social media is an important part in building a stong brand as it is how companies can interact with the wider world.
                    It may be overwhelming to try manage your social medai accounts so we take all the stress out of it and provide clear guidelines to maximize your impact.
                </p>
            </div>
            <div style={{padding: '1rem'}}>
                <h1>What we do</h1>
                <p>With a customized data driven approach we take the stress out of social media management from setting up your accounts 
                    to creating campaigns and posts that grow your community of customers. We also give you strategies and tools to contiue to succeed online.
                </p>
            </div>
                <CapabilitiesListContainer>
                <div>
                        <h3>Strategy</h3>
                        <ul>
                            <li>Brand audit</li>
                            <li>Target audience audit</li>
                            <li>Post scheduling</li>
                            <li>Keyword optimization</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Design</h3>
                        <ul>
                            <li>Brand identity design</li>
                            <li>Post design</li>
                            <li>Campaign design</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Communication</h3>
                        <ul>
                            <li>Audience engagement</li>
                            <li>Communication guidelines</li>
                            <li>Copy writing</li>
                        </ul>
                    </div>
                </CapabilitiesListContainer>
        </SectionWrapper>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{padding: '1rem'}}>
                <h1>Our Packages</h1>
                <p>Our approach focuses on building trust and standing out in a crowded world.
                    We make sure the identity works well online, is visually consistent across all mediums and meets the growth goals set out in the discovery process.
                </p>
            </div>
        </div>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {brandingCards.map(item => <StyledServicesProjectCard key={item.title} title={item.title} services={item.services} price={item.price} dark={item.dark} perMonth={item.perMonth} />)}
            </MiniCardContainer>
        </div>
        <Clients />
        <Footer />
        </>
    )
}

export default SocialMedia
