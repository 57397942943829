import React from 'react'
import testimonialImg from '../assets/testimonial.webp'

function Testimonial({quote,imgUrl,personInfo}) {
    return (
        <section style={{maxWidth: '78rem',height: '100%', width: '100%', margin: '0 auto'}}>
            <div style={{ width: '80%', margin: '1.5rem'}}>
                <h2>"{(quote)? quote :"The Byte-era team setup our social media accounts and developed a content strategy that helped us better engage with our customers."}"</h2>
                <div style={{position: 'relative'}}>
                    <img src={(imgUrl)?imgUrl:testimonialImg} style={{display: 'inline-block',width: '60px', height:'60px',borderRadius: '50%', margin: '0', marginRight: '1rem'}} alt='testimonial'/>
                    <p style={{margin: '0',display: 'inline', position: 'absolute', top: '45%', transform: 'translateY(-50%)'}}>{(personInfo)? personInfo : "CEO Lihle's Beauty Palace"}</p>
                </div>
            </div>
            

        </section>
    )
}

export default Testimonial
