import React from 'react'
import { Navigation } from '../../components/navigationWhiteBG'
import { SectionWrapper } from '../../components/sectionWrapper'
import { projects } from '../../utils/projectInfo'
import { MiniCardContainer, StyledMiniProjectCards, CapabilitiesListContainer } from '../../components/miniProjectCards'
import Clients from '../../components/clients'
import Footer from '../../components/footer'
import StyledServicesProjectCard from '../../components/servicesCards'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function WebDesign() {
    let socialMediaProjects = projects.filter(item => item.categories.indexOf('website') !== -1)
    let trimmedResults = socialMediaProjects.slice(0,3)
    let webDesignCards = [
        {title: 'Basic Package', services:['5 Page Website ','Responsive Design','1 Custom Email','Domain Name','Basic SEO'], price: 'K3000', dark: false},
        {title: 'Growth Package', services:['10 page website ','Responsive Design','5 Custom Emails','Domain Name','Advanced SEO','Google Analytics'], price: 'K7000',dark: true},
        {title: 'Ecommmerce Package', services:['Full Ecommerce Integration','Responsive Design','10 Custom Emails','Domain Name','Advanced SEO','Manage targeted ads','Google analytics'], price: 'K9500', dark: false}]
    
    return (
        <>
        <Navigation disableTransparency={true}/>
        <SectionWrapper flexColumn={true}>
            <div style={{width: '100%', margin: '1rem', minHeight: '100%',position: "relative"}}>
            <div style={{marginTop: '10rem', padding: '1rem'}}>
                <div style={{width: '100%'}}>
                <h1 style={{display: 'flex', width: '100%'}}>Web Design</h1>
                <p style={{maxWidth:'830px'}}>We create websites and landing pages that help tell your brand story and increase 
                retention and conversion as well as build trust with users. </p>
                </div>
            </div>
            </div>
        </SectionWrapper>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {trimmedResults.map(item => <StyledMiniProjectCards key={item.title} to={`/work/${item.projectUrl}`} project={item} />)}
            </MiniCardContainer>
        </div>
        <SectionWrapper>
        <div style={{margin: '1rem'}}>
                <h1>Approach</h1>
                <p>We see websites as an essentail tool that allows brands to tell 
                    their story and showcase what makes them unique. We believe websites 
                    should carry a tone of voice that syncs with the chosen brand attributes 
                    to help send the right message to the target audience. While designing websites, we apply key brand identity elements to create a cohesive experience that reflects both the state of the brand today and their strategic goals and aspirations for the future.
                </p>
            </div>
        </SectionWrapper>
        <div style={{width:'100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{margin:'1rem'}}>
            <h1>Process</h1>
            <div>
                    <h3>1. Dicovery</h3>
                    <p>Gathering requirements: assess current pain point, project goals, user personas,
                        key use cases and industry best practices.
                    </p>
                </div>
                <div>
                    <h3>2. Audit</h3>
                    <p>Analyse exitsting site if available for areas of improvement and perform a competitive analysis.
                </p>
                </div>
                <div>
                    <h3>3. Website structure</h3>
                    <p>Information architecture, site map and copy writing.
                </p>
                </div>
                <div>
                    <h3>4. Concept design and layout design</h3>
                    <p>Visual positioning, high fidelity wireframes and interactive prototypes where applicable.
                </p>
                </div>
                <div>
                    <h3>5. Developments</h3>
                    <p>Build the web pages and other components.
                </p>
                </div>
                <div>
                    <h3>6. Testing</h3>
                    <p>We perform rigorous testing throughout the development process to ensure output is 
                        of the highest quality. 
                </p>
                </div>
                <div>
                    <h3>7. Maintainance</h3>
                    <p>We offer extensive prost launch maintainace to ensure a smooth post-launch period. 
                </p>
                </div>
            </div>
            </div>
            <div style={{width:'100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{margin: '1rem'}}>
                <h1>What we do</h1>
                <p>The result of our design process generates a consistent website that reflects the brand
                     strategy, visual identity, and verbal identity of the brand. It also helps to establish a 
                    prominent position on the market.
                </p>
            </div>
                <CapabilitiesListContainer>
                <div>
                        <h3>User Experience</h3>
                        <ul>
                            <li> Discovery</li>
                            <li>UI/UX audit </li>
                            <li>Information architecture</li>
                            <li>Webdsite Copy and CTAs</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Design &amp; front-end</h3>
                        <ul>
                            <li>Visual design &amp; interactions</li>
                            <li>Front-end development</li>
                            <li>Iconography &amp; illustrations</li>
                            <li>3rd party integrations</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Tech stack</h3>
                        <ul>
                            <li>ReactJS</li>
                            <li>Gatsby</li>
                            <li>Wordpress</li>
                        </ul>
                    </div>
                </CapabilitiesListContainer>
        </div>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto'}}>>
            <div style={{padding: '1rem'}}>
                <h1>Our Packages</h1>
                <p>We have proven and effective packages to meet your marketing needs. With hundreds of new websites being built everyday, it’s imperative that 
                    you get the right developer hired at the competitive website development packages. We are also helping businesses to get noticed in the vast sea of websites and promoting them on various platforms for the maximum benefit.
                </p>
            </div>
        </div>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {webDesignCards.map(item => <StyledServicesProjectCard key={item.title} title={item.title} services={item.services} price={item.price} dark={item.dark} />)}
            </MiniCardContainer>
        </div>
        <Clients />
        <Footer />
        </>
    )
}

export default WebDesign
