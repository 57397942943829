import React from 'react'
import styled  from 'styled-components'
import {Link}  from 'react-router-dom'
import { device } from '../utils/device'

export const GradientCard = styled.span`
position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: -1 !important;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    pointer-events: none;
`


const MiniProjectCards = ({className, to, project}) => {
    return(
        <div className={className}>
        <Link to={to}>
            <div className={'mini-cards'}>
                <div className={'mini-content'}>
                <div>{project.client}</div>
                <div>
                    <h3 style={{marginBottom: '6px'}}>{project.title}</h3>
                    <div className={'line'}></div>
                </div>
                <div><p style={{marginBottom: '6px'}}>{project.categories.map(item => <span>#{item} </span>)}</p></div>
                <img className={'mini-img'} alt='project card' src={project.thumbnailUrl} srcSet={project.srcSet} />
                </div>
            </div>
            <GradientCard />
        </Link>
        </div>
    )
}
export const MiniCardContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%; 
justify-content: center;
content: '';
margin: 0 auto;
@media ${device.tablet}{
    flex-direction: row;
    width: 100%;
    justify-content: space-around; 
}
`

export const StyledMiniProjectCards = styled(MiniProjectCards)`
width: 100%;
box-sizing: border-box;
position: relative;
margin-bottom:  2rem;
@media ${device.tablet}{
    width: 33%;
    margin: 0.6rem;
}
box-sizing: border-box;
&>a:link, &>a:visited,&>a:active{
    text-decoration: none;
    color: black;
}
.line{
    opacity: 0;
    content: '';
    transition: all 0.3s ease;
    z-index: 5;
    height: 2px;
    background-color: white;
    width: 10%;

}
.mini-cards:hover{
    .line{
        opacity: 1;
        width:100%;
    }
}
.mini-cards{
    width: calc( 100% - 4rem);
    height: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 2rem;
    color: white;
    h3,h4{color:white}
    
    
}
.mini-img {
    position: absolute;
    width: 100%;
    height:100%;
    object-fit: cover;
    z-index: -5;
    top:0;
    left:0;
}
.mini-content{
    padding-top: 3rem;
}

`
export const CapabilitiesListContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    &> div{
        margin: 1rem;
        width: 100%;

    }
    @media ${device.tablet}{
        flex-direction: row;
        justify-content: space-around;
    }
`
