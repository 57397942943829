import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/device'

const ServicesCard = ({className, title, services, price,dark, perMonth, lineHeight,grid})=>{
    let showPerMonth 
    if(perMonth){
        showPerMonth = '/mo';
    }else {
        showPerMonth = ''
    }
    return (
        <div className={className}>
            <h2>{title}</h2>
            <ul className='list'>
                {services.map(item => <li>{'- '+ item}</li>)}
            </ul>
            <div><span className='price'>{price}</span>   {showPerMonth}</div>
        </div>
    )
}

const StyledServicesProjectCard = styled(ServicesCard)`
width: 100%;
box-sizing: border-box;
position: relative;
padding: 2rem 1rem 1.5rem;
margin-bottom:  2rem;
transition: all 0.3s ease-in;
background-color: #f9f9f9;
&:hover{
    box-shadow: 10px 20px 55px rgba(152,68,183,0.15);
    transform: translateY(-20px);
}
${props => props.dark && 'background-color: black; color: white !important;'}
&>h2{
    ${props => props.dark && 'color: white !important;'}
}

@media ${device.tablet}{
    ${props => (props.grid)? 'width: 25%;': 'width: 33%;'}
    margin: 0.6rem;
}
.price{
    font-weight: 700;
    font-size: 36px;
}
.list{
    list-style: none;
    margin-left: 0;
    ${props => (props.lineHeight)? '': 'line-height: 38px;'}
    

}
`


export default StyledServicesProjectCard
