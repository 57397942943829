import React, { useState } from 'react'
import { Link, NavLink } from "react-router-dom";
import  { useScrollPosition } from './scrollPosition'
import { device } from '../utils/device'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import BElogo from '../assets/logo-colored.png'

export const Navigation = () => {
  const [hideOnScroll, setHideOnScroll] = useState(true)
  //isTransparent state to toggle the transparency of the navbar
  const [ isTransparent, setIsTransparent ] = useState(false)
  // isOpen state to toggle open or close nav
  const [isOpen,setIsOpen] = useState(false)
  const toggleNav = (e)=> {
    e.preventDefault()
    setIsOpen(!isOpen)
  }
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow) setIsOpen(false)
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
    false,
    false,
    300
  )

  return (
    <header style={{display: 'flex', width: '100%'}}>
      <Nav show={hideOnScroll} showMobile={isOpen}>
      <NavBackground isBgTransparent={isTransparent} showMobile={isOpen} />
      <NavLogo><Link to='/'style={{textDecoration: 'none'}}><img src={BElogo} alt='colored-logo' /><LogoText navIsOpen={isOpen} isWhite={isTransparent}>Byte-era</LogoText></Link></NavLogo>
      <StyledNavToggleIcon navIsOpen={isOpen} handleToggle={toggleNav} isWhite={isTransparent}/>
      <NavList showMobile={isOpen} isWhite={isTransparent}>
      <NavListItem activeClassName='selected' to='/'><span>Let's Digitize</span></NavListItem>
        <NavListItem activeClassName='selected' to='/agency'><span>Agency</span></NavListItem>
        <NavListItem activeClassName='selected' to='/work'><span>Work</span></NavListItem>
        <NavListItem activeClassName='selected' to='/contact'><span>Contact</span></NavListItem>
      </NavList>
    </Nav>
    </header>
  )
}
const NavToggleIcon = ({ navIsOpen,handleToggle, className })=>{
  return(
    <div onClick={handleToggle} className={className}>{navIsOpen?<FontAwesomeIcon icon={faTimes} />:<FontAwesomeIcon icon={faBars} />}</div>
  )
}
const LogoText = styled.h3`
  margin: 0;
  color: black;
  display: inline-block;
`
const StyledNavToggleIcon = styled(NavToggleIcon)`
  display: none;

  @media ${device.maxTablet} {
    display: block;
    order: 3;
    z-index: 200;
    padding-top: 20px;
    text-align: right;
    ${ props => props.navIsOpen? 'margin-right: 0rem;':'margin-right: 1rem;'}
    color: black;
  }

`
const Nav = styled.nav`
  display: flex;
  width: 100vw;
  ${props => props.showMobile && 'justify-content: flex-start;'}
  ${props => !props.showMobile && 'justify-content: space-between;'}
  position: fixed;
  top: 0rem;
  z-index: 100;
  visibility: ${props => (props.show ? 'visible' : 'none')};
  transition: all 0.3s ease-in-out;
  transform: ${props => (props.show ? 'none' : 'translate(0, -100%)')};
  @media ${device.maxTablet} {
    display: flex;
    height: ${props => props.showMobile ? '100vh': '70px'};
    flex-direction: column;
    flex-wrap: wrap;
    ${props => props.showMobile && 'align-items: center;'}
    ${props => props.showMobile && 'justify-content: space-around !important;'}
    
    

    &:first-child{
    }
    & > ul > {
      flex-direection: column;
    & > li {
      width: 100%;
      text-align: center;
      display: block;
    }
  }
  }
`
const NavLogo = styled.span`
  padding: 0rem;
  margin: 1rem 0rem;
  margin-left: 2rem;
  z-index: 110;
  @media ${device.maxTablet} {
    margin-left: 1rem;
  }
  & * img {
    margin: 0;
    display: inline-block;
    margin-right: 0.5rem;
    max-width: 30px;
    vertical-align: bottom;
  }
`
const NavList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0rem;
  margin: 1rem 0rem;
  margin-right: 2rem;
  z-index: 210;
  cursor: pointer;
  color: black;
  @media ${device.maxTablet} {
    display:  ${props => props.showMobile ? 'flex': 'none'};
    flex-direction: column;
    color: black;
    height: 40%;
    justify-content: space-evenly;
    margin: 0;
    align-items: center;
  }
`
const NavListItem = styled(NavLink)`
  margin-left: 1rem;
  transtion: all 0.4s ease-in-out;

  &:link,&:visited{
    color: inherit;
    text-decoration: none;
  }
  & > span {
    position: relative;
    transition: all 0.3s ease-in;
  }
  & > span:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 0px;
    height: 2px;
    margin: 5px 0 0;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.75s;
    opacity: 1;
    background-color: white;
  }
  &:hover{
    & > span:before,
    & > span:after {
        width: 100%;
        opacity: 1;
        background: linear-gradient(45deg, rgba(251,86,7,1) 0%, rgba(255,0,110,1) 51%, rgba(131,56,236,1) 100%);
      }
  }
  @media ${device.maxTablet} {
    margin: 0;
  }
  & + .selected{
    & > span:before,
    & > span:after {
        width: 100%;
        opacity: 1;
        background: linear-gradient(45deg, rgba(251,86,7,1) 0%, rgba(255,0,110,1) 51%, rgba(131,56,236,1) 100%);
      }
  }
`
const NavBackground = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: white;
opacity: 1;
transition: all 0.3s ease-in-out;
transform: scaleY(1);

`

