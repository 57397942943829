import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/device'



const SectionInfo = styled.div`
  display: block;
  width: 1rem;
  position: sticky;
  top: 220px;
  left: 6px;
  & > span {
    font-weight: bold;
    text-align: center;
    & > div{
      transform:  rotate(-90deg);
      font-size: 12px;
    }
  }

`
const SectionWrapper = styled.section`
  margin: 0 auto;
  margin-bottom: 70px;
  max-width: 78rem;
  display: flex;
  width: 100%;
  flex-direction: ${props => props.flexColumn? ' column':'row'};
  position: relative;
  align-items: center;
  justify-content: center;
`

export { SectionInfo, SectionWrapper }