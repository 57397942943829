import React,{ useEffect, useState } from "react"
import Hero from '../components/hero'
import Description from '../components/description'
import Services from '../components/services'
import Clients from '../components/clients'
import { Navigation } from '../components/navigationWhiteBG'
import Projects from '../components/projects'
import Footer from '../components/footer'
import Statement from '../components/statement'
//import LetsDigitize from "../components/letsDigitize"
import teeImg from '../assets/tee2cropped-small.jpg'
import Reel from '../components/Reel'
import { CSSTransition } from 'react-transition-group';
import { MiniCardContainer } from '../components/miniProjectCards'
import StyledServicesProjectCard from '../components/servicesCards'

import ReactGA from 'react-ga';
import Testimonial from "../components/testimonial"
ReactGA.initialize('UA-180326440-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function Home() {
  let serviceMiniDesignCards = [
    {title: 'Creative', services:['We believe our creativity knows no limits.'], dark: false},
    {title: 'Data Driven', services:['Our decisions guided by data.'],dark: true},
    {title: 'Innovative', services:['We find new ways to solve challenges.'], dark: false},
    {title: 'Passionate', services:['Above all else we truely love what we do.'], dark: false}]

  const [inProp, setInProp] = useState(false);
  useEffect(() => {
    setInProp(!inProp)
    
  }, [])
  return <>
    <Navigation disableTransparency={true}/>
    <CSSTransition timeout={500} in={inProp} classNames="my-node">
         <div> 
      <Statement />
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto'}}>
            <div style={{padding: '1rem'}}>
                <h1>Why Us?</h1>
                <p>Our approach focuses on building trust and standing out in a crowded world.
                    We make sure you succeed online, that your brand is visually consistent across all mediums and meets your growth goals.
                </p>
            </div>
        </div>
        <div style={{width: '100%', maxWidth: '78rem', margin: '0 auto', padding: '1rem'}}>
            <MiniCardContainer>
                {serviceMiniDesignCards.map(item => <StyledServicesProjectCard lineHeight={true} grid='4' key={item.title} title={item.title} services={item.services} price={item.price} dark={item.dark} />)}
            </MiniCardContainer>
        </div>
      <Services/>
      <Clients />      
      <Testimonial imgUrl={teeImg} quote="They helped me re-align my marketing stategy and reduce my customer acquisition costs" personInfo="CEO of Lihle's Beauty Palace" />
      <Testimonial  personInfo="CEO of Beauty Originz" />
      <Footer/>
      </div>  
      </CSSTransition>
  </>
}
