
import React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import employee3 from '../assets/employee3.png'
import employee4 from '../assets/employee4.png'
import tee from '../assets/tee2cropped.png'
import teeSmall from '../assets/tee2cropped-small.jpg'
import teeMedium from '../assets/tee2cropped-medium.jpg'

import tabo from '../assets/tabo.png'
import taboSmall from '../assets/tabo-small.jpg'
import taboMedium from '../assets/tabo-medium.jpg'


function MobileTeamCarousel() {
    return (
        <CarouselProvider 
        orientation={'horizontal'}
        naturalSlideWidth={200}
        naturalSlideHeight={230}
        totalSlides={3}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={2} 
       >
        <Slider>
          <Slide index={0}><div style={{height: '100%',  paddingRight: '20px'}}><img style={{objectFit: 'cover'}} alt='profile-1' src={taboSmall} srcSet={ `${taboSmall} 300w, ${taboMedium} 768w, ${tabo} 1280w`} /></div></Slide>
          <Slide index={1}><div style={{ height: '100%',  paddingRight: '20px'}}><img style={{objectFit: 'cover', height: '100%'}} alt='profile-1' src={employee3}/></div></Slide>
          <Slide index={2}><div style={{ height: '100%',  paddingRight: '20px'}}><img style={{objectFit: 'cover', height: '100%'}} alt='profile-1' src={employee4}/></div></Slide>
        </Slider>
        <div style={{position: 'absolute', right: '2rem', marginTop: '1rem'}}>
        <ButtonBack style={{border: 'none',backgroundColor:'black',color:'white'}}>&larr;</ButtonBack>
        <ButtonNext style={{border: 'none',background:'linear-gradient(120deg,rgba(251,86,7,1) 0%,rgba(255,0,110,1) 51%,rgba(131,56,236,1) 100%)',color:'white'}}>&rarr;</ButtonNext>
        </div>
        </CarouselProvider>
    )
}

export default MobileTeamCarousel
