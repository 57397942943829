import testImg from '../assets/agency-pic.jpg'
import { LihlesMockup, LogoSVGLihles,lihlesBanner, fbPageLihle  } from '../assets/lihles-beauty-palace'
import {
  BannerBO,
  BannerBOSmall,
  BannerBOMedium,
  InfoBO,
  InfoBOMedium,
  InfoBOSmall,
  BagMockupBO,
  BagMockupBOSmall,
  BagMockupBOMedium,
  OpeningSoonBO,
  OpeningSoonBOSmall,
  OpeningSoonBOMedium,
  StylescapeBO, 
  LogoBOSVG
} from '../assets/beauty-originz'
import { ProjectBannerImg,
  ProjectBannerImgSmall, 
  StylescapeAlafea,
  StylescapeAlafeaSmall,
  StylescapeAlafeaMedium,
  LogoOptnAlafea,
  LogoOptnAlafeaSmall,
  LogoAlafea, 
  LogoAlafeaSmall,
  LogoSVGAlafea, 
  MockupAlafea,
  MockupAlafeaSmall
} from '../assets/alafea-institute'
import { OfficeMaphosa,
  OfficeMaphosaSmall,
  OfficeMaphosaMedium,
  LayoutMaphosa,
  LayoutMaphosaSmall,
  LayoutMaphosaMedium,
  MockupMaphosa,
  MockupMaphosaSmall,
  MockupMaphosaMedium,
  MockupMaphosa2,
  MockupMaphosa2Small,
  MockupMaphosa2Medium,
  LogoSVG } from '../assets/maphosa-and-associates'
import {ProjectBannerDephlex,
  ProjectBannerDephlexSmall,
  ProjectBannerDephlexMedium,
  LogoDephlex,
  LogoDephlexSmall,
  LogoDephlexMedium,
  LogoVariationsDephlex,
  LogoVariationsDephlexSmall,
  LogoVariationsDephlexMedium,
  BannerDephlex,
  BannerDephlexSmall,
  BannerDephlexMedium,
  IdentityDephlex,
  IdentityDephlexSmall,
  IdentityDephlexMedium,
  LogoSVGDephlex,
  LogoOldDephlex,
  LogoOldDephlexSmall,
  LogoOldDephlexMedium
} from '../assets/dephlex-creatives'

export const categories = [
    'branding',
    'digital-marketing',
    'website',
    'social-media'
]
export const sectors = [
    'corporate',
    'legal',
    'retail',
    'fintech'
]
export const projects = [
    {
        title: 'Building A Bold New Identity',
        client: 'Beauty Originz',
        description: 'Beauty Originz identity design and marketing',
        categories: ['branding','website'],
        thumbnailUrl: BannerBOSmall,
        srcSet:  `${BannerBOSmall} 300w, ${BannerBOMedium} 768w, ${BannerBO} 1280w`,
        logoUrl: LogoBOSVG,
        projectUrl: 'beauty-originz'
    },
    {
        title: 'Maphosa & Associates Online Redesign',
        client: 'Maphosa & Associates',
        description: 'basic desc',
        categories: ['branding','legal','website'],
        thumbnailUrl: OfficeMaphosaSmall,
        logoUrl: LogoSVG,
        srcSet: `${OfficeMaphosaSmall} 300w, ${OfficeMaphosaMedium} 768w, ${OfficeMaphosa} 1280w`,
        projectUrl: 'maphosa-and-associates'
    },
    {
      title: 'Unbutton Your Beauty',
      client: "Lihle's Beauty Palace",
      description: 'Lihle\'s identity design, website and marketing',
      categories: ['branding','website','social-media'],
      thumbnailUrl:  lihlesBanner,
      srcSet:  `${ lihlesBanner} 300w`,
      logoUrl: LogoSVGLihles,
      projectUrl: 'lihles-beauty-palace'
  },
    {
        title: 'An Educational Consultant With An Edge',
        client: 'Alafea-institute',
        description: 'Logo Design and Website',
        categories: ['branding','website'],
        thumbnailUrl: ProjectBannerImgSmall,
        logoUrl: LogoSVGAlafea,
        srcSet: `${ProjectBannerImgSmall} 300w, ${ProjectBannerImg} 768w, ${ProjectBannerImg} 1280w`,
        projectUrl: 'alafea-institute'
    },
    {
        title: 'An Exciting Re-brand of Dephlex Creatives',
        client: 'Dephlex Creatives',
        description: 're-branding an agency',
        categories: ['branding'],
        thumbnailUrl: ProjectBannerDephlexSmall,
        logoUrl: LogoSVGDephlex ,
        srcSet:  `${ProjectBannerDephlexSmall} 300w, ${ProjectBannerDephlexMedium} 768w, ${ProjectBannerDephlex} 1280w`,
        projectUrl: 'dephlex-creatives'
    }
]

export const blogs = [
    {id: 'blog1',
    projectUrl: 'lihles-beauty-palace',
    post: [{type: 'title', content: 'Launching Lihle\'s Beauty Palace'},
    {type: 'fullWidthImg', content: {src: lihlesBanner, alt: 'sample-img'}},
    {type: 'sectionTitle', content: 'The Challenge'},
    {type: 'text', content: 'Lihle\'s Beauty Palace came to us to design their brand identity and coordinate their launch. As a new cosmetics retailer which sold goods online, we had to ensure that as many potential clients as possible would get to see the launch. We needed a clean and classy launch with consistent content that would allow the customers to easily view and inquire about the products as well as share them with interested friends.'},
    {type: 'img', content: {src: fbPageLihle, alt: 'sample-img-small'}},
    {type: 'sectionTitle', content: 'Approach'},
    {type: 'text', content: 'We began by setting project goals and aligning the goals with the vision for the company. This allowed us to determine the key characteristics of the brand and helped us focus on a few features that would be necesarry for a successful launch.'},
    {type: 'sectionTitle', content: 'Deliverables'},
    {type: 'text', content: 'We crafted a strong brand that resonates with women of all ages and gives them access to affordable makeup products online in a time of great uncertainity due to COVID-19. We coordinated the launch of Lihle\'s Facebook page and Whatsapp Business Account which enabled customers to easily view products and pricing as well as quickly enquire about the products on platforms that they are already familiar with and use on a regular basis. '},
    {type: 'text', content: 'Through engaging visuals and strong copy, the Byte-era team was able to craft stories about each product and the problems they solve for customers, this allowed the page to quickly grow a large following of repeat customers from scratch. Going forward Lihle\'s will launch their ecommerce store to better serve customers in unique ways that may not be possible on third-party platforms. '},
    {type: 'comment', content: {text: 'Great work and amazing team I will definately be working with Byte-era on our next project. We hit all out deadlines and targets.',
  name: 'Thembelihle Tshuma, CEO of Lihle\'s Beauty Palace',img: {src: testImg, alt:'testing profile img'}}}]
    },
    {id: 'blog2',
    projectUrl: 'beauty-originz',
    post: [{type: 'title', content: 'Beauty Originz A Bold New Brand'},
    {type: 'fullWidthImg', content: {src: InfoBOSmall, alt: 'sample-img', srcSet: `${InfoBOSmall} 300w, ${InfoBOMedium} 768w, ${InfoBO} 1280w`}},
    {type: 'sectionTitle', content: 'The Challenge'},
    {type: 'text', content: 'When we partnered with Beauty Originz, their aim was simple: re-align the brand as the go to online store for beauty and cosmetics products amongst a crowded industry.'},
    {type: 'img', content: {src: OpeningSoonBOSmall, alt: 'sample-img-small', srcSet: `${OpeningSoonBOSmall} 300w, ${OpeningSoonBOMedium} 768w, ${OpeningSoonBO} 1280w`}},
    {type: 'sectionTitle', content: 'Approach'},
    {type: 'text', content: 'We set in motion a transformation of the company identity from the logo and color scheme to the marketing materials. With the focus being on clearer and more captivating ad design Beauty Originz was able to greatly improve the customer experience which is at the core of their values.'},
    {type: 'sectionTitle', content: 'Deliverables'},
    {type: 'text', content: 'When Beauty Originz originally started out, they identified a great problem to solve: customers couldn’t find quality and affordable cosmetics for their skin tone. Beauty Originz provided them with the best products, a simple order process and great service. But with more competitors entering the market Beauty Originz needed to establish itself as the industry lead in order to remain successful.'},
    {type: 'text', content: 'Through extensive research we found that customers new to purchasing cosmetics online required more product information and tutorials before feeling comfortable enough to make a purchase. As a result, I developed a new brand identity and we shifted the marketing efforts towards providing more comprehensive details on the products and how to use them all while keeping the consistent design language developed to further strengthen the trust in company and its products.'},
    {type: 'text', content: 'Deliverables for this projects included a new logo brand identity and multiple pieces of marketing material from digital ads to infographics and Instagram carousel style tutorials.'},    
    {type: 'img', content: {src: StylescapeBO, alt: 'sample-img-small'}},
    {type: 'sectionTitle', content: 'Results'},
    {type: 'text', content: 'With the new design language, the conversion rate on ads increased significantly and through branded tutorial style posts Beauty Originz saw greater customer engagement on its post which led to more sales.'},
    {type: 'sectionTitle', content: 'What we learned'},
    {type: 'text', content: 'Working with a digital first business like Beauty Originz taught me that consistency in both design and customer service can separate a company from its competition and is key to continued growth within an industry.'},
    {type: 'comment', content: {text: 'Great work and amazing team I will definately be working with Byte-era on our next project. We hit all our targets and are still growing.',
  name: 'Thembelihle Grace Tshuma, CEO of Beauty Originz',img: {src: testImg, alt:'testing profile img'}}}]
    },
    {id: 'blog3',
    projectUrl: 'alafea-institute',
    post: [{type: 'title', content: 'An Educational Consultant With An Edge'},
    {type: 'fullWidthImg', content: {src: ProjectBannerImgSmall, alt: 'sample-img', srcSet: `${ProjectBannerImgSmall} 300w, ${ProjectBannerImgSmall} 768w, ${ProjectBannerImg} 1280w`}},
    {type: 'sectionTitle', content: 'The Challenge'},
    {type: 'text', content: 'Alafea Institute was founded to empower amazing educators seeking to re-define their teaching via professional and personal development. we partnered with them to build their brand identity that would convey their expertice within their industry.'},
    {type: 'img', content: {src: LogoOptnAlafea, alt: 'sample-img-small', srcSet: `${LogoOptnAlafeaSmall} 300w, ${LogoOptnAlafeaSmall} 768w, ${LogoOptnAlafea} 1280w`}},
    {type: 'sectionTitle', content: 'Approach'},
    {type: 'text', content: 'We started with analyzing the client\’s vision of a new symbol. As they wanted the new symbol to exudes heritage and expertice, a new brand sign was expected to be more traditional and simple with some abstract elements. In addition, the client asked to utilize an orange and brown color palette.'},
    {type: 'text', content: 'With this simpler logo and color scheme I then built out the brand identity a specified the guidelines for the use of the new logo and the overall aesthetic.'},
    {type: 'fullWidthImg', content: {src: StylescapeAlafea, alt: 'sample-img-small', srcSet: `${StylescapeAlafea} 300w, ${StylescapeAlafea} 768w, ${StylescapeAlafea} 1280w`}},
    {type: 'sectionTitle', content: 'Deliverables'},
    {type: 'text', content: 'The final logo was completed in flat minimalistic style. The shield symbol resembles a family crest as well as crests of other educational institutions like Harvard and Yale thus giving off a sense of expertices and heritage. This design managed to combine both the vision of the client and the values of the company through simple shapes.'},
    {type: 'img', content: {src: LogoAlafeaSmall, alt: 'sample-img-small', srcSet: `${LogoAlafeaSmall} 300w, ${LogoAlafeaSmall} 768w, ${LogoAlafea} 1280w`}},
    {type: 'sectionTitle', content: 'Results'},
    {type: 'text', content: 'The result was a strong brand that exudes class and heritage which reassures potential customers that Alafea Institute can empower them.'},
    {type: 'comment', content: {text: 'Wow what great service, I love it! This is exactly what our business has been lacking. ',
  name: 'Alafea Tomlinson, CEO of Alafea Institute',img: {src: testImg, alt:'testing profile img'}}}]
    },
    {id: 'blog4',
    projectUrl: 'maphosa-and-associates',
    post: [{type: 'title', content: 'Building Maphosa & Associates Website + Online Presence'},
    {type: 'fullWidthImg', content: {src: OfficeMaphosaSmall, alt: 'sample-img', srcSet: `${OfficeMaphosaSmall} 300w, ${OfficeMaphosaMedium} 768w, ${OfficeMaphosa} 1280w`}},
    {type: 'sectionTitle', content: 'The Challenge'},
    {type: 'text', content: 'Maphosa & Associates was setup to provide affordable legal services for the people of the Eastern Cape. However, faced with an ever expanding digital world Maphosa & Associates looked to update their website to convert more leads and grow their digital presence to better serve the community.'},
    {type: 'img', content: {src: MockupMaphosa2Small, alt: 'sample-img-small',srcSet: `${MockupMaphosa2Small} 300w, ${MockupMaphosa2Medium} 768w, ${MockupMaphosa2} 1280w`}},
    {type: 'sectionTitle', content: 'Approach'},
    {type: 'text', content: 'I set out to analyze the major pain points on their existing site and what changes could be made to improve the overall user experience. Better user experiences lead to more conversions in this case the goal was to get leads to book an appointment.'},
    {type: 'text', content: 'After analyzing my research I determined the information hierachy, sitemap and then created high fidelity mockups to test the effectiveness of the design. Once the new designs were validated the site went live.'},
    {type: 'sectionTitle', content: 'Deliverables'},
    {type: 'text', content: 'The result was a clean minimal website that is easy to navigate with a high conversion rate. Clients can now easily see the services offered before making a booking. Along with a new site we delivered a clean new brand identity that reflects the firm\'s standing in the industry.'},
    {type: 'img', content: {src: LayoutMaphosaSmall, alt: 'sample-img-small', srcSet: `${LayoutMaphosaSmall} 300w, ${LayoutMaphosaMedium} 768w, ${LayoutMaphosa} 1280w`}},
    {type: 'text', content: ' '},
    {type: 'img', content: {src: MockupMaphosaSmall, alt: 'sample-img-small', srcSet: `${MockupMaphosaSmall} 300w, ${MockupMaphosaMedium} 768w, ${MockupMaphosa} 1280w`}},
    {type: 'sectionTitle', content: 'Results'},
    {type: 'text', content: 'The new site had an increased conversion rate and a reduced bounce rate thus hitting the targets set out at the start of the project.'},
    {type: 'comment', content: {text: 'Fantastic, I am totally blown away with the designs.',
  name: 'Faith Maphosa, Senior Partner at Maphosa & Associates',img: {src: testImg, alt:'testing profile img'}}}]
    },
    {id: 'blog5',
    projectUrl: 'dephlex-creatives',
    post: [{type: 'title', content: 'An Exciting Re-brand of Dephlex Creatives'},
    {type: 'fullWidthImg', content: {src: BannerDephlexSmall, alt: 'sample-img', srcSet: `${BannerDephlexSmall} 300w, ${BannerDephlexMedium} 768w, ${BannerDephlex} 1280w`}},
    {type: 'sectionTitle', content: 'The Challenge'},
    {type: 'text', content: 'Dephlex Creatives is a digital agency based in Wuhan, China that aims to provide its clients with degital solutions from logo design to web development. As the company aged its initial logo became dated and did not refelct the companies new identity hence a redesign was needed.'},
    {type: 'img', content: {src: LogoOldDephlexSmall, alt: 'sample-img-small', srcSet: `${LogoOldDephlexSmall} 300w, ${LogoOldDephlexMedium} 768w, ${LogoOldDephlex} 1280w`}},
    {type: 'sectionTitle', content: 'Approach'},
    {type: 'text', content: 'With the goal to expand across continents Dephlex needed a new identity that was simple, clean and instilled trust. To achieve this we simplified the previous logo to its most basic shapes and utilized a diffierent color scheme to signify each of the intended new markets that the agency would operate in.'},
    {type:'text', content: 'With this simpler logo and color scheme we then built out the brand identity and specified the guidelines for the use of the new logo.'},
    {type: 'sectionTitle', content: 'Deliverables'},
    {type: 'text', content: 'For this project Dephlex Creatives recieved a new logo and brand identity with numerous mockups to use in there marketing material.'},
    {type: 'img', content: {src: LogoVariationsDephlexMedium, alt: 'sample-img-small', srcSet: `${LogoVariationsDephlexMedium} 300w, ${LogoVariationsDephlexMedium} 768w, ${LogoVariationsDephlex} 1280w`}},
    {type: 'sectionTitle', content: 'Results'},
    {type: 'text', content: 'The result was a modern brand mark that matched the companies vision and values.'},
    {type: 'img', content: {src: IdentityDephlexSmall, alt: 'sample-img-small', srcSet: `${IdentityDephlexSmall} 300w, ${IdentityDephlexMedium} 768w, ${IdentityDephlex} 1280w`}}]
    }
]