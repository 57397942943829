import React from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { SectionWrapper, SectionInfo } from './sectionWrapper'
import { device } from '../utils/device'
import { projects } from '../utils/projectInfo'
import {GradientCard} from './miniProjectCards'

let topFiveProjects = projects.slice(0,4);

const ProjectFlexContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.flexColumn?'column':'row'};
    margin-left: 0.8rem;
    margin-right: 2rem;
    width: 100%;
`
const TestProjectCard = ({className, project})=>{
  return(
    <div className={className}>
    <Link to={`./work/${project.projectUrl}`}>
        <div className={'mini-cards'}>
            <div className={'mini-content'}>
            <div><img style={{webkitFilter: 'invert(1)',filter: 'invert(1)', maxWidth: '60px'}} src={project.logoUrl} alt={`${project.logoUrl}logo`}/></div>
            <div>
                <h3 style={{marginBottom: '6px'}}>{project.title}</h3>
                <div className={'line'}></div>
            </div>
            <div><p style={{marginBottom: '6px'}}>{project.categories.map(item => <span>#{item} </span>)}</p></div>
            <img className={'mini-img'} alt='project card' src={project.thumbnailUrl} srcSet={project.srcSet} />
            </div>
        </div>
        <GradientCard />
    </Link>
    </div>
  )
} 

export const StyledTestProjectCards = styled(TestProjectCard)`
width: 100%;
box-sizing: border-box;
position: relative;
margin-bottom:  2rem;
box-sizing: border-box;
&>a:link, &>a:visited,&>a:active{
    text-decoration: none;
    color: black;
}
.line{
    opacity: 0;
    content: '';
    transition: all 0.3s ease;
    z-index: 5;
    height: 2px;
    background-color: white;
    width: 10%;

}
.mini-cards:hover{
    .line{
        opacity: 1;
        width:100%;
    }
}
.mini-cards{
    width: calc( 100% );
    height: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 5rem 4rem;
    @media ${device.tablet}{
      padding: 8rem 4rem;
    }
    color: white;
    h3,h4{color:white}
    
    
}
.mini-img {
    position: absolute;
    width: 100%;
    height:100%;
    object-fit: cover;
    z-index: -5;
    top:0;
    left:0;
}
.mini-content{
    padding-top: 3rem;
}

`
const AltProjectCard = ({className, project})=>{
    return(
        <Link style={{textDecoration: 'none'}} to={`./work/${project.projectUrl}`}>
        <div className={className}>
            <div><img style={{webkitFilter: 'invert(1)',filter: 'invert(1)', maxWidth: '90px'}} src={project.logoUrl} alt={`${project.logoUrl}logo`}/></div>
            <div>{project.categories.map(item => <span>#{item} </span>)}</div>
            <p><span className={'hover'}>{project.title}</span></p>
        </div>
        </Link>
    )
} 
const StyledAltProjectCard = styled(AltProjectCard)`
    width: 100%;
    padding: 4rem 2rem;
    @media ${device.tablet}{
        padding: 9rem 8rem;
    };
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justfy-content: center;
    background-image: url('${props => props.project.thumbnailUrl}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 50px;
    color: white;
    & > p{ 
      font-size: 200%;
    }
    &:hover {
      & > p > .hover:before,
      & > p > .hover:after {
          width: 100%;
          opacity: 1;
        }
    }

    & > p > .hover {
      position: relative;
      transition: all 0.3s ease-in;
    }
    & > p >.hover::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 0px;
      height: 2px;
      margin: 5px 0 0;
      transition: all 0.2s ease-in-out;
      transition-duration: 0.75s;
      opacity: 1;
      background-color: white;
    }
`

export default function Projects(){
    return (
    <div style={{width: '100%'}}>
     <SectionWrapper flexColumn={false}>
        <SectionInfo>
            <span>
                <div>S</div>
                <div>T</div>
                <div>C</div>
                <div>E</div>
                <div>J</div>
                <div>O</div>
                <div>R</div>
                <div>P</div>
            </span>
        </SectionInfo>
        <ProjectFlexContainer flexColumn={true}>
          <div style={{display: 'flex', width:'100%',marginBottom: '50px' }}><h1>Our Projects</h1></div>
          <div className='project__container'>
            {topFiveProjects.map((item) => {return <StyledTestProjectCards key={item.title} project={item} />})}
          </div>
        </ProjectFlexContainer>
      </SectionWrapper>  
    </div>
    )
  }